import moment from 'moment-timezone'
import _orderBy from 'lodash.orderby'
import _compact from 'lodash.compact'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'
import { D_SET_TO_USER_CACHE, D_GET_FROM_USER_CACHE } from '../../../../utilities/action-types'

import {
  findTransportationProfiles,
  getTransportationProfile,
  getGeoLocationTransportationProfilesByIds,
  startTransportationSimulation,
  stopTransportationSimulation,
  getSimulateBusIds,
  getGeoLocationTransportationProfilesWithinRadius
} from '../../../../services/transportation-profiles-service'

import { addAlert } from '../../../../utilities/helpers'
import { findTransportationOwnerProfiles } from '../../../../services/transportation-owners-service'
import { findTransportationOwnerUsers } from '../../../../services/transportation-owner-users-service'

import {
  findTransportationProfileRoutes,
  getBusStopsForProfileRoute, getProfileRoute
} from '../../../../services/transportation-profile-routes-service'

import { genericApiRequests } from '@/utilities/axios-factory'

import {
  getBusStopData, getCoarseGeolocation,
  getRoute,
  getRouteByRouteIdList, googleGeocodeRequest
} from '@/services/maps-service'

import { searchOtpRoutes } from '@/services/st-routes-service'
import { geocodeSearch } from '@/services/bus-stops-service'

const cachedAccounts = {}
const cachedTransportationProfiles = {}

export default {
  name: 'transportation-accounts-vehicles-realtime',
  props: {
    signedInUser: Object,
    selectedAccount: Object,
    hasUserType: Function,
    hasTransportationRole: Function,
    forceRootViewRefresh: Function,
    transportTypes: Array,
    agencies: Array
  },
  data () {
    return {
      offsetHeight: 0,
      regions: [{ text: 'Accra', value: 'accra', metadata: { coordinates: [-0.1996, 5.5837] } }],
      selectedRegion: null,
      isTransportationProfilesLoading: false,
      isTransportationProfilesForSimulatorLoading: false,
      transportationProfiles: [],
      transportationProfilesForSimulator: [],
      transportationProfilesSearchKey: '',
      transportationProfileToSimulateSearchKey: '',
      isTransportUser: null,
      isStaffUser: null,
      isSimulationInProgress: false,
      transportationProfileSimulatorIds: [],
      modalBusDetailsDialog: false,
      modalSimulateSelectDialog: false,
      selectedTransportationProfileSimulatorId: null,
      selectedTransportationProfileId: null,
      selectedTransportationProfileIdToSimulate: null,
      busDetailInProgress: false,
      busDetails: null,
      modalBusDetailsError: '',
      stMapApis: null,
      mapboxAccessToken: process.env.VUE_APP_MAPBOX_KEY,
      maptilerKey: process.env.VUE_APP_MAPTILER_KEY,
      markerOptions: { genericBusImageUrl: `${location.origin}/images/bus-marker-generic.png`, startMarkerClass: 'st-map-marker-start', endMarkerClass: 'st-map-marker-end' }
    }
  },
  mounted: function () {
    // We only want to add Ottawa on staging/local envs we have test buses there
    if (process.env.VUE_APP_ENV !== 'production') {
      this.regions.push({ text: 'Ottawa', value: 'ottawa', metadata: { coordinates: [-75.6914, 45.3999] } })
    }

    this.isTransportUser = this.$props.hasTransportationRole('ADMIN')
    this.isStaffUser = this.$props.hasUserType('staff')

    if (this.isComponentValid()) {
      this.$nextTick(this.onResize.bind(this))

      this.$store.dispatch(D_GET_FROM_USER_CACHE, 'realtimeMapDefaultRegion').then((cachedValue) => {
        if (cachedValue) {
          this.selectedRegion = this.regions.find(o => o.value === cachedValue)
        } else {
          this.selectedRegion = this.regions.find(o => o.value === 'accra')
        }
      })

      this.stMapApis = {
        genericApiRequests,
        getRouteByRouteIdList,
        getRoute,
        getBusStopsForProfileRoute,
        getTransportationProfileRoute: getProfileRoute,
        addAlert,
        getBusStops: ({ searchradius, ...rest }) => (getBusStopData({ ...rest, radius: searchradius })),
        getGeoLocationTransportationProfilesByIds,
        getGeoLocationTransportationProfilesWithinRadius,
        // getCachedRegions,
        googleGeocodeRequest,
        getCoarseGeolocation,
        searchRoutes: searchOtpRoutes,
        geocodeRequest: (keywords, longlat) => geocodeSearch({ keywords, longlat })
      }

      this.searchTransportationProfiles()

      if (this.isValidToRunSimulator()) {
        this.getSimulatorIds()
      }
    } else {
      alert('Please select an account first')
    }
  },
  watch: {
    selectedRegion (changedObj) {
      if (changedObj) {
        this.$store.dispatch(D_SET_TO_USER_CACHE, { key: 'realtimeMapDefaultRegion', value: changedObj.value, expiryInSeconds: null })
      } else {
        this.$store.dispatch(D_SET_TO_USER_CACHE, { key: 'realtimeMapDefaultRegion', value: null, expiryInSeconds: null })
      }
    },
    transportationProfilesSearchKey (val) {
      clearTimeout(this.onBoundsChangedTimeoutHandle)
      clearTimeout(this.searchTransportationProfilesHandle)

      if (val) {
        this.searchTransportationProfilesHandle = setTimeout(async () => {
          this.searchTransportationProfiles(val)
        }, 800)
      } else {
        this.searchTransportationProfilesHandle = setTimeout(async () => {
          this.searchTransportationProfiles()
        }, 1000)
      }
    },
    transportationProfileToSimulateSearchKey (val) {
      clearTimeout(this.searchTransportationProfilesHandle)
      if (val) {
        this.searchTransportationProfilesHandle = setTimeout(async () => {
          this.searchTransportationProfilesForSimulation(val)
        }, 800)
      } else {
        this.searchTransportationProfilesHandle = setTimeout(async () => {
          this.searchTransportationProfilesForSimulation()
        }, 1000)
      }
    }
  },
  methods: {
    onResize () {
      const breadcrumbHeight = 73 + this.$store.getters.appToolbarHeight + this.$store.getters.appFooterHeight // breadcrumb + site header + site footer
      const headerElement = this.$refs ? this.$refs.headerElement : null
      this.offsetHeight = (headerElement ? headerElement.clientHeight : 0) + breadcrumbHeight
    },
    isComponentValid () {
      return (this.$route.params.accountId && this.$route.params.accountId !== '0') || this.isStaffUser
    },
    isValidToRunSimulator () {
      return this.$props.hasUserType('admin') && process.env.VUE_APP_ALLOW_SIMULATION === 'true'
    },
    async runTransportationProfileSimulator () {
      try {
        await startTransportationSimulation({
          routeId: 'accra',
          from: '(5.5711306,-0.2143098)',
          to: '(5.6467064,-0.1345249)',
          transportationProfileId: this.selectedTransportationProfileIdToSimulate
        })

        this.isSimulationInProgress = true
        this.selectedRegion = this.regions.find(o => o.value === 'accra')

        this.simulatorDelayedCheckHandle = setTimeout(() => {
          this.getSimulatorIds()
          this.searchTransportationProfiles()
        }, 3000)
      } catch (err) {
        this.isSimulationInProgress = false

        addAlert({
          message: `Error in simulation request: ${err?.error ? err.error.message : (err?.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      }
    },
    async getCachedTransportationProfile (id) {
      if (cachedTransportationProfiles[id] && cachedTransportationProfiles[id].expiresTimestamp > Date.now()) {
        return cachedTransportationProfiles[id]
      }

      const transportationProfile = await getTransportationProfile({
        id,
        filter: { include: ['transportationOwnerProfile', 'transportationEquipment', { currentDriverObj: 'stUser' }, { currentAssistantObj: 'stUser' }] }
      })

      if (transportationProfile) {
        transportationProfile.expiresTimestamp = Date.now() + (3600 * 1000)
      }

      cachedTransportationProfiles[id] = transportationProfile
      return transportationProfile
    },
    async getCachedAccounts (transportationProfileId) {
      if (cachedAccounts[transportationProfileId] && cachedAccounts[transportationProfileId].expiresTimestamp > Date.now()) {
        return cachedAccounts[transportationProfileId]
      }

      let transportationOwnerProfile = await findTransportationOwnerProfiles({ where: { transportationProfileId }, include: 'transportationOwner' })

      if (transportationOwnerProfile && transportationOwnerProfile.length) {
        transportationOwnerProfile = transportationOwnerProfile[0]
        transportationOwnerProfile.transportationOwner.expiresTimestamp = Date.now() + (3600 * 1000)
        cachedAccounts[transportationProfileId] = transportationOwnerProfile.transportationOwner
      } else {
        cachedAccounts[transportationProfileId] = null
      }

      return cachedAccounts[transportationProfileId]
    },
    async onBusClusterClickCallback (event, feature) {
      // alert('cluster bus clicked: ' + JSON.stringify(feature))
    },
    onBusesUpdated (buses) {
      this.updatedBuses = buses
    },
    onBoundsChanged () {
      clearTimeout(this.onBoundsChangedTimeoutHandle)
      this.onBoundsChangedTimeoutHandle = setTimeout(() => {
        this.searchTransportationProfiles()
      }, 800)
    },
    onBusesInit (buses) {
      this.searchTransportationProfiles()
    },
    async onBusMarkerClickCallback (event, feature) {
      this.modalBusDetailsDialog = true
      this.modalBusDetailsError = null
      try {
        this.busDetailInProgress = true
        const transportationProfile = await this.getCachedTransportationProfile(feature.properties.profileid)

        if (!transportationProfile) {
          throw new Error('No vehicle details found for id: ' + feature.properties.profileid)
        }

        const transportationAccount = await this.getCachedAccounts(transportationProfile.id)

        if (!transportationAccount) {
          throw new Error('No vehicle account found for id: ' + feature.properties.profileid)
        }

        let vehicleType = this.$props.transportTypes.find(o => o.value === transportationProfile.transportType)
        vehicleType = vehicleType ? vehicleType.text : 'Unknown'
        let agencyLabel = this.$props.agencies.find(o => o.value.toLowerCase() === transportationProfile.agencyId.toLowerCase())
        agencyLabel = agencyLabel ? agencyLabel.text : 'Unknown'

        this.busDetails = {
          licensePlateNumber: transportationProfile.licensePlateNumber,
          driverName: transportationProfile.currentDriverObj?.stUser ? ((transportationProfile.currentDriverObj.stUser.firstName || '') + ' ' + (transportationProfile.currentDriverObj.stUser.lastName || '')) : 'Unknown',
          assistantName: transportationProfile.currentAssistantObj?.stUser ? ((transportationProfile.currentAssistantObj.stUser.firstName || '') + ' ' + (transportationProfile.currentAssistantObj.stUser.lastName || '')) : '',
          equipmentIdLabel: transportationProfile.transportationEquipment.deviceId,
          equipmentInventoryLabel: transportationProfile.transportationEquipment.inventoryId || '',
          usesRoutesLabel: transportationProfile.isRouteFree ? 'No' : 'Yes',
          accountName: transportationAccount.companyName,
          agencyLabel,
          vehicleType,
          lastCheckedIn: moment(feature.properties.timestamp).fromNow()
        }

        this.busDetailInProgress = false
      } catch (err) {
        this.busDetailInProgress = false
        this.modalBusDetailsError = `${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
      }
      // alert('bus clicked: ' + JSON.stringify(feature))
    },
    async stopTransportationProfileSimulator () {
      try {
        const simulator = this.transportationProfileSimulatorIds.find(o => this.selectedTransportationProfileSimulatorId === o.value)
        if (confirm('Confirm stopping simulation for ' + simulator.text)) {
          await stopTransportationSimulation({
            id: this.selectedTransportationProfileSimulatorId
          })
          this.isSimulationInProgress = false
          this.selectedTransportationProfileSimulatorId = null
          this.simulatorDelayedCheckHandle = setTimeout(() => {
            this.getSimulatorIds()
            this.searchTransportationProfiles()
          }, 3000)
          // alert('Simulator(s) stopping, please give the server a few seconds to shut it down')
        }
      } catch (err) {
        addAlert({
          message: `Error in stop simulation request: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      }
    },
    async getSimulatorIds () {
      try {
        const transportationProfileSimulatorIds = await findTransportationProfileRoutes({ where: { routeIdList: { like: '%::%' } } })

        if (transportationProfileSimulatorIds && transportationProfileSimulatorIds.length) {
          this.transportationProfileSimulatorIds = transportationProfileSimulatorIds.map(o => {
            const transportationProfile = this.transportationProfiles.find(o2 => o2.id === o.transportationProfileId)
            const simulatorName = transportationProfile ? transportationProfile.licensePlateNumber : o.routeIdList.split('::')[1]
            return { text: simulatorName, value: o.routeIdList.split('::')[1] }
          })
        } else {
          this.transportationProfileSimulatorIds = []
        }

        const simulatorIdsFromApi = await getSimulateBusIds()

        if (simulatorIdsFromApi.length) {
          simulatorIdsFromApi.forEach((simulatorIdFromApi) => {
            if (!this.transportationProfileSimulatorIds.find((o) => (o.value === simulatorIdFromApi))) {
              this.transportationProfileSimulatorIds.push({ text: simulatorIdFromApi, value: simulatorIdFromApi })
            }
          })
        }

        if (this.transportationProfileSimulatorIds.length) {
          this.transportationProfileSimulatorIds.push({ text: 'All', value: 'all' })
        }
      } catch (err) {
        console.log('err', err)
        addAlert({
          message: `Error in simulator ids request: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      }
    },
    async searchTransportationProfilesForSimulation (searchKeywords) {
      try {
        this.isTransportationProfilesForSimulatorLoading = true
        let transportationOwnerUserFilter

        const filter = {
          include: [{ currentDriverObj: 'stUser' }],
          limit: 20
        }

        // If transportation user, then filter by joined transportation owner selectedAccount
        if (this.$props.selectedAccount) {
          filter.join = { relation: 'transportationOwnerProfile', scope: { where: { transportationOwnerId: this.$props.selectedAccount.id } } }
        }

        if (searchKeywords) {
          filter.where = { or: generateKeywordsQueryForOr(searchKeywords, ['licensePlateNumber']) }
          transportationOwnerUserFilter = {
            fields: { id: true },
            where: this.$props.selectedAccount ? { transportationOwnerId: this.$props.selectedAccount.id } : undefined,
            join: { relation: 'stUser', scope: { where: { or: generateKeywordsQueryForOr(searchKeywords, ['firstName', 'lastName']) } } }
          }

          // filter by separate search of driver names which returns ids to add to where clause
          let transportationOwnerUserIds
          if (transportationOwnerUserFilter) {
            transportationOwnerUserIds = await findTransportationOwnerUsers(transportationOwnerUserFilter)
            if (transportationOwnerUserIds && transportationOwnerUserIds.length) {
              filter.where = { currentDriver: { inq: transportationOwnerUserIds.map(o => o.id) } }
            }
          }
        }

        const transportationProfiles = await findTransportationProfiles(filter)

        this.transportationProfilesForSimulator = transportationProfiles.map(o => {
          o.currentDriverName = o.currentDriverObj ? (o.currentDriverObj.stUser.firstName || '') + ' ' + (o.currentDriverObj.stUser.lastName || '') : ''
          return o
        })

        this.transportationProfilesForSimulator = _orderBy(this.transportationProfilesForSimulator, ['disabled'], ['asc'])
      } catch (err) {
        addAlert({
          message: err,
          type: 'error'
        })
      } finally {
        this.isTransportationProfilesForSimulatorLoading = false
      }
    },
    async searchTransportationProfiles (searchKeywords) {
      try {
        let geoLocationTransportationProfiles
        this.isTransportationProfilesLoading = true
        let transportationOwnerUserFilter

        const filter = {
          include: [{ currentDriverObj: 'stUser' }],
          limit: 20
        }

        // If transportation user, then filter by joined transportation owner selectedAccount
        if (this.isTransportUser || this.$props.selectedAccount) {
          filter.join = { relation: 'transportationOwnerProfile', scope: { where: { transportationOwnerId: this.$props.selectedAccount.id } } }
        }

        if (searchKeywords) {
          filter.where = { or: generateKeywordsQueryForOr(searchKeywords, ['licensePlateNumber']) }
          transportationOwnerUserFilter = {
            fields: { id: true },
            where: this.isTransportUser || this.$props.selectedAccount ? { transportationOwnerId: this.$props.selectedAccount.id } : undefined,
            join: { relation: 'stUser', scope: { where: { or: generateKeywordsQueryForOr(searchKeywords, ['firstName', 'lastName']) } } }
          }

          // filter by separate search of driver names which returns ids to add to where clause
          let transportationOwnerUserIds

          if (transportationOwnerUserFilter) {
            transportationOwnerUserIds = await findTransportationOwnerUsers(transportationOwnerUserFilter)
            if (transportationOwnerUserIds && transportationOwnerUserIds.length) {
              filter.where = { currentDriver: { inq: transportationOwnerUserIds.map(o => o.id) } }
            }
          }
        } else {
          // TODO: no keyword search so check if buses are in current radius and coord of map (use map callback that provides current location info)
          if (this.updatedBuses && this.updatedBuses.length) {
            let buses = this.updatedBuses
            if (this.updatedBuses.length > 20) {
              buses = this.updatedBuses.slice(0, 20)
            }

            geoLocationTransportationProfiles = buses
          } else {
            geoLocationTransportationProfiles = await getGeoLocationTransportationProfilesByIds({ limit: 20 })
          }

          geoLocationTransportationProfiles = _compact(geoLocationTransportationProfiles)

          if (geoLocationTransportationProfiles && geoLocationTransportationProfiles.length) {
            filter.where = { id: { inq: geoLocationTransportationProfiles.map(o => o.profileid) } }
          }
        }

        const transportationProfiles = await findTransportationProfiles(filter)

        if ((!geoLocationTransportationProfiles || !geoLocationTransportationProfiles.length) && transportationProfiles && transportationProfiles.length) {
          geoLocationTransportationProfiles = await getGeoLocationTransportationProfilesByIds({ ids: transportationProfiles.map(o => o.id) })
        }

        this.transportationProfiles = transportationProfiles.map(o => {
          o.currentDriverName = o.currentDriverObj ? (o.currentDriverObj.stUser.firstName || '') + ' ' + (o.currentDriverObj.stUser.lastName || '') : ''
          o.lastCheckedIn = ''
          o.disabled = true
          if (geoLocationTransportationProfiles && geoLocationTransportationProfiles.length) {
            const foundGeoLocation = geoLocationTransportationProfiles.find(o2 => o2.profileid === o.id)
            if (foundGeoLocation) {
              o.lastCheckedIn = moment(foundGeoLocation.timestamp).fromNow()
              o.disabled = false
            }
          }

          return o
        })

        this.transportationProfiles = _orderBy(this.transportationProfiles, ['disabled'], ['asc'])
      } catch (err) {
        addAlert({
          message: `Error in vehicle search: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      } finally {
        this.isTransportationProfilesLoading = false
      }
    },
    async transportationProfilesSearchChanged (val) {
      this.selectedTransportationProfileId = val ? val.id : null
    },
    async transportationProfileToSimulateSearchChanged (val) {
      this.selectedTransportationProfileIdToSimulate = val ? val.id : null
    }
  },
  beforeDestroy () {
    clearTimeout(this.simulatorDelayedCheckHandle)
  }
}
